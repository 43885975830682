import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFocus } from 'react-aria';
import { Icon, Typo } from '@geberit/gdds';

// components
import { FyloutItem } from './flyout-item';
import { LocationIcon } from './location-icon';
import { RefsContext } from '../map';

// styles
import {
  Wrapper,
  StyledForm,
  StyledFlyout,
  StyledInputWrapper,
  StyledInput,
} from './search.styles';

// types
import { IHighlightedprediction } from '../locator.types';
import { SearchProps } from './search.types';

// utils
import { useOnClickOutside } from '../../../../utils/use-on-click-outside';
import { useKeyboardNavigation } from './utils/use-keyboard-navigation';
import { useIsDesktop } from '../../../App/SizeProvider';
import { useBodyScroll } from '../utils/use-body-scroll';

export function Search({
  suggestions = [],
  flyoutLabel,
  placeholder,
  iconName = 'Location',
  onChange,
  onSubmit,
  onLocationClick,
  value,
  geoLocated,
  geoLocationPending,
  ...props
}: Readonly<SearchProps>) {
  const fromRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [showFlyout, setShowFlyout] = useState(false);
  const isDesktop = useIsDesktop({ gdds: true });
  const { locatorWrapperRef } = useContext(RefsContext);

  const { focusProps } = useFocus({
    onFocusChange: (focused) => {
      if (!focused) {
        return;
      }

      setIsFocused(true);
      setShowFlyout(true);
    },
  });

  /**
   * on iPhone the virtual keyboards moves the top outside the viewport. then the search box in fullscreen is not visible to
   * the user. we need to "scroll top" after the keyboard animation is done.
   */
  useEffect(() => {
    const isIPhone =
      typeof window !== 'undefined' &&
      (window.navigator.platform === 'iPhone' ||
        window.navigator.userAgent.indexOf('iPhone') !== -1);

    if (!isIPhone) return;

    const keyboardAnimationTime = 350; // animation time is documented with 250ms. we use a higher value just to be save :)
    const scrollY = window.scrollY;
    let scrollFixTimeout: Timeout | undefined;

    if (isFocused) {
      scrollFixTimeout = setTimeout(() => {
        window.scrollTo(0, 0);
      }, keyboardAnimationTime);
    }

    return () => {
      if (isFocused) {
        clearTimeout(scrollFixTimeout);
        window.scrollTo(0, scrollY);
      }
    };
  }, [isFocused]);

  useBodyScroll(isFocused && !isDesktop);

  const { focusedIndex, setFocusedItemIndex, ...keyboardNavigationProps } = useKeyboardNavigation({
    onSelect: () => {
      getOnSelectHandler(suggestions[focusedIndex])();
    },
    itemsCount: suggestions.length,
  });

  const shouldDisplayFlyout = useMemo(
    () => showFlyout && suggestions.length > 0,
    [showFlyout, suggestions],
  );

  useOnClickOutside(fromRef, () => {
    setShowFlyout(false);

    if (isDesktop) {
      setIsFocused(false);
    }
  });

  function onChangeHandler(val: string, final?: boolean) {
    onChange(val, final);
  }

  useEffect(() => {
    if (showFlyout) setFocusedItemIndex(-1);
  }, [setFocusedItemIndex, showFlyout]);

  useEffect(() => {
    if (!locatorWrapperRef) {
      return;
    }

    if (isFocused && !isDesktop) {
      locatorWrapperRef.current?.classList.add('fixed');
    } else {
      locatorWrapperRef.current?.classList.remove('fixed');
    }
  }, [isFocused, isDesktop, locatorWrapperRef]);

  const getOnSelectHandler = (item: IHighlightedprediction) => () => {
    const { secondaryText, mainText } = item;

    onChangeHandler(`${mainText} ${secondaryText}`.trimEnd(), true);
    setShowFlyout(false);
    setIsFocused(false);
  };

  const onSubmitHandler = () => {
    inputRef.current?.blur();
    setShowFlyout(false);
    setIsFocused(false);
    onSubmit();
  };

  return (
    <Wrapper isFocused={isFocused}>
      <StyledForm
        ref={fromRef}
        onSubmit={(event) => {
          event.preventDefault();
          onSubmitHandler();
        }}
      >
        <StyledInputWrapper darkIcon={isFocused || Boolean(value)}>
          {
            <span
              className="leftIcon lens"
              onClick={onSubmitHandler}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  onSubmitHandler();
                }
              }}
            >
              <Icon symbol="Search" />
            </span>
          }
          {
            <span
              className="leftIcon backButton"
              onClick={() => {
                setIsFocused(false);
                setShowFlyout(false);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setIsFocused(false);
                  setShowFlyout(false);
                }
              }}
            >
              <Icon symbol={isFocused ? 'ArrowLeft' : 'Search'} />
            </span>
          }
          <StyledInput
            autoComplete="off"
            type="text"
            {...props}
            {...focusProps}
            {...keyboardNavigationProps}
            placeholder={isFocused ? undefined : placeholder}
            boxShadow={!isFocused && !showFlyout}
            value={value}
            onChange={(event) => {
              setShowFlyout(true);
              onChangeHandler(event.target.value);
            }}
            ref={inputRef}
          />
          {value && isFocused ? (
            <span
              className="resetButton"
              onClick={() => {
                onChangeHandler('');
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  onChangeHandler('');
                }
              }}
            >
              <Icon symbol="Close" />
            </span>
          ) : (
            <LocationIcon
              active={geoLocated}
              pending={geoLocationPending}
              onClick={onLocationClick}
            />
          )}
        </StyledInputWrapper>
        {shouldDisplayFlyout && (
          <StyledFlyout>
            <Typo variant="p2" fontWeight={500}>
              {flyoutLabel}
            </Typo>
            {suggestions.map((item, index) => {
              const { hightlightedMainText, secondaryText } = item;

              return (
                <FyloutItem
                  key={item.mainText}
                  focused={focusedIndex === index}
                  iconName={iconName}
                  onSelectOption={getOnSelectHandler(item)}
                  mainText={hightlightedMainText}
                  secondary_text={secondaryText}
                />
              );
            })}
          </StyledFlyout>
        )}
      </StyledForm>
    </Wrapper>
  );
}
