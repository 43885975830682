import { useContext, useEffect } from 'react';

// types
import { IEntryWithKey } from '../providers/filter-provider';
import { IWithDistance } from '../utils/pins-manager';

// utils
import { useIsDesktop, useIsMobile } from 'components/App/SizeProvider';
import { config } from '../config';
import { RefsContext } from '../providers';

export function useAnimation(activePin: IWithDistance<IEntryWithKey> | null) {
  const { locatorWrapperRef, portalRef } = useContext(RefsContext);
  const isDesktop = useIsDesktop({ gdds: true });
  const isMobile = useIsMobile();

  useEffect(() => {
    const wrapperRefCurrent = locatorWrapperRef.current;

    if (isDesktop) {
      if (portalRef.current) portalRef.current.style.maxHeight = '';
      return;
    }

    if (!wrapperRefCurrent || !portalRef.current) {
      return;
    }

    if (activePin) {
      wrapperRefCurrent.style.setProperty('--map-height', '100vh');
      wrapperRefCurrent.style.setProperty('--transition-delay', '0s');
      portalRef.current.style.maxHeight = '';
      window.scrollTo({ top: 0 });
    }

    return () => {
      if (activePin && wrapperRefCurrent) {
        wrapperRefCurrent.style.setProperty(
          '--map-height',
          `${isMobile ? config.mobile.mapHeight : config.tablet.mapHeight}px`,
        );
        wrapperRefCurrent.style.setProperty(
          '--transition-delay',
          `${config.mobile.transitionDuration / 10}s`,
        );
      }
    };
  }, [activePin, isDesktop, isMobile, locatorWrapperRef, portalRef]);
}
