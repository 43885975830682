import { ThemeProvider } from 'styled-components';
import { Typo, Icon, Switch, Tooltip } from '@geberit/gdds';

// styles
import { StyledPartnerSwitch } from './partner-switch.styles';

// utils
import { useLocatorConfiguration } from '../../utils/use-locator-configuration';
import { useIsDesktop } from 'components/App/SizeProvider';
import useTheme from 'utils/hooks/use-theme';
import { useSearchParamsState } from 'utils/hooks/use-search-params-state';
import { decodingContent } from 'utils/decodingContent';
import { useTracking } from 'utils/tracking/track';

const LocatorSwitch = (props) => {
  const theme = useTheme();

  return (
    // colors: { ...theme.colors } -> returns cl17 which is primaryLight as the first value
    <ThemeProvider theme={{ ...theme, colors: { ...theme.colors } }}>
      <Switch {...props}></Switch>
    </ThemeProvider>
  );
};

interface PartnerSwitchProps {
  reverse?: boolean;
}

export function PartnerSwitch({ reverse = false }: Readonly<PartnerSwitchProps>) {
  const [state, setSearchParamsState] = useSearchParamsState(['partner', 'addr']);
  const locatorConfig = useLocatorConfiguration();
  const isDesktop = useIsDesktop({ gdds: true });
  const { trackLocator } = useTracking();

  if (!locatorConfig?.filter?.showPartnerSwitch) {
    return null;
  }

  return (
    <StyledPartnerSwitch reverse={reverse}>
      <Tooltip
        initialtooltipPosition={isDesktop ? 'left' : 'right'}
        offset={{ top: 16, left: isDesktop ? 12 : 0, right: isDesktop ? 0 : 12 }}
        promptText={decodingContent(locatorConfig?.filter?.tooltipText)}
      >
        <Icon symbol="info" />
      </Tooltip>
      <Typo variant="p2">{decodingContent(locatorConfig?.filter?.prioritisePartners)}</Typo>
      <LocatorSwitch
        onChange={({ target: { checked } }) => {
          setSearchParamsState({ partner: checked });
          if (checked)
            trackLocator({
              click_intent: '"partner priorisiren" click	',
              search_term: state.addr ?? '',
              value: 2,
            });
        }}
        checked={Boolean(state.partner)}
      />
    </StyledPartnerSwitch>
  );
}
