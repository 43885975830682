import { PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// utils
import { useIsoLang } from 'scenes/MetaData';
import { globalsServerSelector } from 'utils/selectors/globalsSelectors';
import { Log } from 'services/log';
import { useUCConsent } from 'features/cookie-consent';

// google maps params
const libraries = ['geometry' as const, 'places' as const, 'geocoding'];
const version = '3.53';
const callback = '__googleMapsLoaded';

if (typeof window !== 'undefined') {
  window[callback] = () => {
    const event = new CustomEvent('google-map-loaded', {});
    document.dispatchEvent(event);
  };
}

export function GoogleMapProvider({ children }: Readonly<PropsWithChildren>) {
  const locale = useIsoLang();
  const [isLoaded, setIsLoaded] = useState(false);
  const server = useSelector(globalsServerSelector);
  const consent = useUCConsent();

  useEffect(() => {
    const canGoogleBeLoaded = consent.GoogleMaps === true;
    if (!canGoogleBeLoaded) return;

    if (!server?.googleMapsApiKey) {
      Log.error('google maps api key not provided!');
    }

    const onGoogleMapLoaded = () => {
      setIsLoaded(true);
    };
    document.addEventListener('google-map-loaded', onGoogleMapLoaded);

    if (!document.getElementById('google-map-script')) {
      const [language] = locale.split('_');
      const script = document.createElement('script');
      script.id = 'google-map-script';
      script.src = `https://maps.googleapis.com/maps/api/js?${new URLSearchParams({
        key: server?.googleMapsApiKey ?? '',
        libraries: libraries.join(','),
        callback,
        v: version,
        language,
      }).toString()}`;

      document.body.append(script);
    } else {
      setIsLoaded(Boolean(window['google']?.maps));
    }

    return () => {
      setIsLoaded(false);
      document.removeEventListener('google-map-loaded', onGoogleMapLoaded);
    };
  }, [locale, server?.googleMapsApiKey, consent?.GoogleMaps]);

  return <>{isLoaded && children}</>;
}
