import styled from 'styled-components';

export const Wrapper = styled.div<{ isFocused?: boolean }>`
  margin-right: 1rem;
  background-color: ${({ theme }) => theme.colors.white};

  && {
    ${({ isFocused }) =>
      isFocused
        ? `
      position: fixed;
      margin-right: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      padding: 1rem 1rem 0 1rem;
      width: unset;
      `
        : ''}
  }

  && .backButton {
    ${({ isFocused }) =>
      isFocused
        ? `
          cursor: pointer;
          width: 2.625rem;
          height: 100%;
          top: 0;
          left: 0;
          `
        : ''};
  }

  @media ${({ theme }) => theme.device.medium} {
    ${({ isFocused }) => (isFocused ? 'padding: 1.5rem 1.5rem 0 1.5rem' : '')};
  }

  @media ${({ theme }) => theme.device.large} {
    && {
      all: unset;
    }

    & .backButton {
      display: none;
    }
  }
`;

export const StyledForm = styled.form`
  position: relative;
`;

export const StyledInputWrapper = styled.div<{ darkIcon: boolean }>`
  position: relative;
  z-index: 1;

  & span {
    svg {
      fill: ${({ theme, darkIcon }) => (darkIcon ? theme.colors.black : theme.colors.grey12)};
    }
  }

  & .leftIcon {
    position: absolute;
    top: 0.8125rem;
    bottom: 0.8125rem;
    left: 1rem;
    width: 1.125rem;
    height: 1.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.lens {
      display: none;
    }

    @media ${({ theme }) => theme.device.large} {
      display: inline-flex;

      &.backButton {
        display: none;
      }

      &.lens {
        display: initial;
      }
    }
  }

  & > span:last-child {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledInput = styled.input<{ boxShadow: boolean }>`
  && {
    box-sizing: border-box;
    cursor: auto;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 0 2.75rem 0 2.625rem;
    height: 2.75rem;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ boxShadow }) => (boxShadow ? '0 2px 6px 0 rgba(0, 0, 0, 0.26)' : 'none')};
  }

  &::placeholder {
    color: rgba(${({ theme }) => theme.colors.transparentBlack}, 0.36);
  }

  &&:hover {
    box-shadow: ${({ boxShadow }) => (boxShadow ? '0 2px 6px 0 rgba(0, 0, 0, 0.26)' : 'none')};
  }

  &&:focus {
    box-shadow: ${({ boxShadow }) => (boxShadow ? '0 2px 6px 0 rgba(0, 0, 0, 0.26)' : 'none')};
    border: 1px solid ${({ theme }) => theme.colors.black};
  }

  &:active {
    border: 1px solid ${({ theme }) => theme.colors.black};
  }

  + span {
    cursor: pointer;
  }
`;

export const StyledFlyout = styled.ul<{ boxShadow?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 100%;
  padding: 1rem 0 0.5rem 1rem;
  left: 1px;
  right: 1px;
  margin: 0;
  z-index: 0;

  @media ${({ theme }) => theme.device.large} {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.26);
    top: 0;
    padding: 3.5rem 0 0.5rem 1rem;
  }

  && > p {
    padding: 0;
    margin: 0 0 0.5rem 0;
    color: ${({ theme }) => theme.colors.grey11};
  }
`;
