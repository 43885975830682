import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { usePathname, useSearchParams } from 'next/navigation';

type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

export function useSearchParamsState<T extends string[]>(
  searchParamNames: [...T],
  defaultValues: string[] = [],
): readonly [
  searchParamsState: Record<[...T][number], string | undefined>,
  setSearchParamsState: (
    newState: PartialRecord<[...T][number], string | undefined>,
    pathname?: string,
  ) => void,
] {
  const router = useRouter();
  const currentPathname = usePathname();
  const readOnlySearchParams = useSearchParams();

  const searchParamsState = useMemo(
    () =>
      searchParamNames.reduce((acc, searchParamName, index) => {
        const value = readOnlySearchParams.get(searchParamName);
        acc[searchParamName as string] =
          value && value !== 'undefined' ? value : defaultValues[index];
        return acc;
      }, {} as Record<[...T][number], string | undefined>),
    [defaultValues, searchParamNames, readOnlySearchParams],
  );

  const setSearchParamsState = useCallback(
    (values: PartialRecord<[...T][number], string | undefined>, pathname?: string) => {
      const searchParams = new URLSearchParams(Array.from(readOnlySearchParams.entries()));
      Object.entries(values).forEach(([key, value]: [key: string, value: string]) => {
        if (value) {
          searchParams.set(key, value);
        } else {
          searchParams.delete(key);
        }
      });
      router.push(
        {
          pathname: pathname || currentPathname,
          search: searchParams.toString(),
        },
        undefined,
        { shallow: !pathname },
      );
    },
    [currentPathname, readOnlySearchParams, router],
  );

  return [searchParamsState, setSearchParamsState];
}
